import { MyAccountMenuItem } from "~/components/MyAccount/MyAccountMenu/myAccountMenuTypes";

const MY_ACCOUNT_MENU_ITEMS: MyAccountMenuItem[] = [
  {
    label: "Dashboard",
    key: "dashboard",
    href: "/my-account",
    isForCreditAccountOnly: false,
    isForAccountAdminOnly: false,
    dataTestId: "sidebarDashboardLink",
  },
  {
    label: "Invoices and Statement",
    key: "financial",
    href: "/my-account/financial",
    isForCreditAccountOnly: true,
    isForAccountAdminOnly: true,
    dataTestId: "sidebarInvoicesStatementLink",
  },
  {
    label: "My Orders",
    key: "orders",
    href: "/my-account/orders",
    isForCreditAccountOnly: false,
    isForAccountAdminOnly: false,
    dataTestId: "sidebarMyOrdersLink",
  },
  {
    label: "Address Book",
    key: "addressBook",
    href: "/my-account/address-book",
    isForCreditAccountOnly: false,
    isForAccountAdminOnly: false,
    dataTestId: "sidebarAddressBookLink",
  },
  {
    label: "Manage Users",
    key: "manageUsers",
    href: "/my-account/manage-users",
    isForCreditAccountOnly: false,
    isForAccountAdminOnly: true,
    dataTestId: "sidebarManageUsersLink",
  },
];

export function getMyAccountMenuItems(isCreditAccount: boolean, isWebAdmin: boolean): MyAccountMenuItem[] {
  return MY_ACCOUNT_MENU_ITEMS.filter((item: MyAccountMenuItem) => {
    return (isCreditAccount || !item.isForCreditAccountOnly) && (isWebAdmin || !item.isForAccountAdminOnly);
  });
}
